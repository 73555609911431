import Vue from 'vue'
import locale from 'element-ui/lib/locale'

// TODO: insert ui components here:
// TODO: uncomment styles in front/src/styles/theme/index.scss
import Button from 'element-ui/lib/button'
import Card from 'element-ui/lib/card'
import Checkbox from 'element-ui/lib/checkbox'
import CheckboxGroup from 'element-ui/lib/checkbox-group'
import Col from 'element-ui/lib/col'
import Container from 'element-ui/lib/container'
import Footer from 'element-ui/lib/footer'
import Header from 'element-ui/lib/header'
import Loading from 'element-ui/lib/loading'
import Main from 'element-ui/lib/main'
import Menu from 'element-ui/lib/menu'
import Submenu from 'element-ui/lib/submenu'
import MenuItem from 'element-ui/lib/menu-item'
import Notification from 'element-ui/lib/notification'
import Option from 'element-ui/lib/option'
import Pagination from 'element-ui/lib/pagination'
import Progress from 'element-ui/lib/progress'
import Radio from 'element-ui/lib/radio'
import RadioGroup from 'element-ui/lib/radio-group'
import Row from 'element-ui/lib/row'
import Select from 'element-ui/lib/select'
import Table from 'element-ui/lib/table'
import TableColumn from 'element-ui/lib/table-column'
import Form from 'element-ui/lib/form'
import FormItem from 'element-ui/lib/form-item'
import Input from 'element-ui/lib/input'

import TheLoader from '@/modules/core/components/TheLoader'
import i18n from './i18n'

locale.i18n(i18n.t.bind(i18n))

// SET DEFAULT VALUES
Row.props.type = { type: String, default: 'flex' }
Select.props.size = { type: String, default: 'medium' }
Input.props.size = { type: String, default: 'medium' }
// Button.props.size = { type: String, default: 'small' }

const components = [
  Button,
  Card,
  Checkbox,
  CheckboxGroup,
  Col,
  Container,
  Footer,
  Header,
  Loading,
  Main,
  Menu,
  MenuItem,
  Submenu,
  Notification,
  Option,
  Pagination,
  Progress,
  Radio,
  RadioGroup,
  Row,
  Select,
  Table,
  TableColumn,
  Form,
  FormItem,
  Input,

  TheLoader,
]

components.forEach(Component => {
  if (typeof Component.install === 'function') {
    Vue.use(Component)
  } else if (Component.name) {
    Vue.component(Component.name, Component)
  } else {
    // eslint-disable-line no-console
    console.error('Unable to register component', Component)
  }
})

const defaultOptions = {
  duration: 20000,
  offset: 0,
}

Object.assign(Vue.prototype, {
  $notify: {
    ...Notification,
    success: options => Notification({
      type: 'success',
      ...defaultOptions,
      ...options,
    }),
    info: options => Notification({
      type: 'info',
      ...defaultOptions,
      ...options,
    }),
    warning: options => Notification({
      type: 'warning',
      ...defaultOptions,
      ...options,
    }),
    error: options => Notification({
      type: 'error',
      ...defaultOptions,
      ...options,
    }),
  },
  // $msgbox: MessageBox,
  // $confirm(message, options = {}) {
  //   const mergedOptions = {
  //     closeOnClickModal: false,
  //     confirmButtonText: i18n.t('buttons.confirm'),
  //     cancelButtonText: i18n.t('buttons.cancel'),
  //     ...options,
  //   }

  //   const blur = () => setTimeout(() => document.activeElement.blur(), 1)

  //   return MessageBox.confirm(message, options.title || '', mergedOptions)
  //     .then(() => {
  //       blur()
  //       return true
  //     })
  //     .catch(() => {
  //       blur()
  //       return false
  //     })
  // },
})
