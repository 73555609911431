export default {
  message: 'Домашня сторінка',

  langCode: 'UA',
  langName: 'Українська',

  core: {
    loading: 'Завантаження',
    greeting: 'Ласкаво просимо до',
    description: 'Digital Office Testing System',
    about: 'Про D.O.T.S.',
    service: 'Сервіс',
    copyright: 'Авторське право 2021 D.O.T.S. ТОВ',
    rightsReserved: 'Усі права захищені'
  },

  buttons: {
    save: 'Зберегти',
    cancel: 'Скасувати',
    login: 'Увійти',
    signup: 'Зареєструватися',
    next: 'Наступне',
    beginTest: 'Почати тест',
    continue: 'Далі'
  },

  messages: {
    leavingWarning: 'Якщо ви покинете сторінку зараз, ви не можете пройти тест знову.',
    serverError: 'Помилка сервера',
    testServerError: 'Сервер тимчасово недоступний. Спробуйте пройти тест пізніше.'
  },

  auth: {
    gender: 'Стать',
    firstName: 'Імʼя',
    lastName: 'Прізвище',
    birthday: 'Рік народження',
    position: 'Посада',
    lang: 'Зручна мова тестування',
    agreeWithUserDataProcessing: 'Не заперечую обробці моїх персональних данних'
  },

  gender: {
    male: 'Чоловік',
    female: 'Жінка'
  },

  tests: {
    isEnoughTime: 'Я маю достатньо часу для проходження тесту',
    testIsEnd: 'Тестування завершено.',
    thanksForYourTime: 'Дякуємо за Ваш час!',
    resultsToHr: 'За результатами прохання звернутися до вашого спеціаліста з HR',
    ops: 'Оооой.',
    wrongLink: 'Ваше посилання неправильне або термін дії минув',
    enoughTime: 'Я маю достатньо часу виконати завдання не відволікаючись',
    personalData: 'Я не заперечую щодо обробки моїх персональних даних'
  },

  help: {
    arrow: 'Стрілки ↑ ↓',
    arrowDesc: 'Пересування між варіантами відповіді.',
    enter: 'Enter ↵',
    enterDesc: 'Підтвердження відповіді та перехід до наступного запитання.',
    space: 'Пробіл',
    spaceDesc: 'Взаємодія з чекбоксом'
  },

  form: {
    name: {
      login: 'Увійти',
      signup: 'Зареєструватися',
      edit: 'Редагувати профіль',
      create: 'Створити профіль',
    },
    email: {
      label: 'Електронна пошта',
      placeholder: 'Будь-ласка введіть вашу електронну пошту',
    },
    password: {
      label: 'Пароль',
      placeholder: 'Будь-ласка введіть ваш пароль',
    },
    confirmPassword: {
      label: 'Підтвердити пароль',
      placeholder: 'Будь-ласка підтвердіть ваш пароль',
    },
    firstName: {
      label: 'Імʼя',
      placeholder: 'Будь-ласка введіть ваше імʼя',
    },
    lastName: {
      label: 'Прізвище',
      placeholder: 'Будь-ласка введіть ваше прізвище',
    },
    birthday: {
      label: 'Дата народження',
    },
    gender: {
      label: 'Стать',
      male: 'Чоловік',
      female: 'Жінка',
    },
    subdivision: {
      label: 'Підрозділ',
    },
    position: {
      label: 'Посада',
    },
    status: {
      label: 'Статус',
    },
    targetPosition: {
      label: 'Цільова посада',
    },
    notes: {
      label: 'Примітки',
    },
  },

  el: {
    datepicker: {
      placeholder: 'Будь-ласка оберіть дату вашого народження',
      now: 'Зараз',
      today: 'Сьогодні',
      cancel: 'Відміна',
      clear: 'Очистити',
      confirm: 'OK',
      selectDate: 'Обрати дату',
      selectTime: 'Обрати час',
      startDate: 'Дата початку',
      startTime: 'Час початку',
      endDate: 'Дата завершення',
      endTime: 'Час завершення',
      prevYear: 'Попередній рік',
      nextYear: 'Наступний рік',
      prevMonth: 'Попередній місяць',
      nextMonth: 'Наступний місяць',
      year: '',
      month1: 'Січень',
      month2: 'Лютий',
      month3: 'Березень',
      month4: 'Квітень',
      month5: 'Травень',
      month6: 'Червень',
      month7: 'Липень',
      month8: 'Серпень',
      month9: 'Вересень',
      month10: 'Жовтень',
      month11: 'Листопад',
      month12: 'Грудень',
      weeks: {
        sun: 'Нд',
        mon: 'Пн',
        tue: 'Вт',
        wed: 'Ср',
        thu: 'Чт',
        fri: 'Пт',
        sat: 'Сб',
      },
      months: {
        jan: 'Січ',
        feb: 'Лют',
        mar: 'Бер',
        apr: 'Кві',
        may: 'Тра',
        jun: 'Чер',
        jul: 'Лип',
        aug: 'Сер',
        sep: 'Вер',
        oct: 'Жов',
        nov: 'Лис',
        dec: 'Гру',
      },
    },
    select: {
      noData: 'Немає даних',
      placeholder: 'Обрати',
    },
    table: {
      emptyText: 'Немає даних',
    },
  },
}
