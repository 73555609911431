import Vue from 'vue'
import { App } from './modules/core'
import router from './plugins/router'
import i18n from './plugins/i18n'
import store from './store'
import '@/plugins/element-ui'
import '@/styles/index.scss'
import '@/plugins/mq'
import '@/plugins/mixins'

Vue.config.productionTip = false

store.dispatch('setRouter', router)
store.dispatch('setLocale', { locale: i18n.locale })

new Vue({
  i18n,
  router,
  store,
  render: h => h(App),
}).$mount('#app')
