import last from 'lodash/last'
import get from 'lodash/get'
import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'

const pluck = (arr, prop) => (
  Array.isArray(arr) ? arr.map(a => a[prop]) : []
)

const head = list => (
  (Array.isArray(list) || typeof list === 'string') ? list[0] : undefined
)

export {
  get,
  pluck,
  last,
  head,
  isEmpty,
  cloneDeep,
}
