import { AuthPage, TestWrapper } from '../modules/tests'
import { ErrorPage } from '../modules/core'

export const routes = [
  {
    path: '/auth/:id',
    name: 'home',
    component: AuthPage,
  },
  {
    path: '/test/:id',
    name: 'test',
    component: TestWrapper,
    meta: { layout: 'EmptyLayout' },
  },
  {
    path: '/error',
    name: 'error',
    component: ErrorPage,
    meta: { layout: 'EmptyLayout' },
  },
  { path: '*', redirect: { name: 'error' } },
]
