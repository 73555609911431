import Vue from 'vue'
import { breakpointList } from './mq'

Vue.mixin({
  methods: {
    maxmq(size) {
      if (typeof size === 'string') {
        const viewIndex = breakpointList
          .findIndex(({ name }) => name === this.$mq)
        const sizeIndex = breakpointList
          .findIndex(({ name }) => name === size)

        return viewIndex <= sizeIndex
      }

      return breakpointList
        .filter(({ value }) => value <= size)
        .some(({ name }) => name === this.$mq)
    },
  },
})
