import Vue from 'vue'
import VueI18n from 'vue-i18n'
import langCollection from '@/locales'

const locales = langCollection.locales.map(({ langCode }) => langCode)

Vue.use(VueI18n)

export default new VueI18n({
  locale: 'uk',
  locales,
  defaultLocale: 'uk',
  fallbackLocale: 'uk',
  messages: langCollection.messages,
})
