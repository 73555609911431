<template>
  <div class="empty-layout">
    <el-container class="default-layout" v-if="$store.getters.isAppLoaded">
      <el-main>
        <slot />
      </el-main>

      <el-footer height="fit-content" >
        <TheFooter />
      </el-footer>
    </el-container>
  </div>
</template>

<script>
import TheFooter from './components/TheFooter'

export default {
  name: 'EmptyLayout',

  components: {
    TheFooter,
  },
}
</script>

<style scoped lang="scss">
.default-layout {
  height: 100vh;

  .el-main {
    /deep/ > section {
      display: block;
    }
  }
}
</style>
