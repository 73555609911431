<template>
  <el-container class="default-layout" v-if="$store.getters.isAppLoaded">
    <el-header>
      <LocaleSwitcher />
    </el-header>

    <el-main>
      <slot />
    </el-main>

    <el-footer height="fit-content" >
      <TheFooter />
    </el-footer>
  </el-container>
</template>

<script>
import LocaleSwitcher from './components/LocaleSwitcher'
import TheFooter from './components/TheFooter'

export default {
  name: 'DefaultLayout',

  components: {
    LocaleSwitcher,
    TheFooter,
  },
}
</script>

<style lang="scss" scoped>
.default-layout {
  height: 100vh;

  .el-header {
    padding: 10px 20px;
    display: flex;
    justify-content: flex-end;
  }

  .el-main {
    /deep/ > section {
      display: block;
    }
  }

  .the-logo {
    width: fit-content;
    margin-right: 20px;
  }

  .nav-menu {
    width: 100%;
  }
}
</style>
