import { GET } from '@/plugins/http'

export default {
  state: () => ({
    user: null,
  }),

  mutations: {
    setUser: (state, user) => { state.user = user },
  },

  actions: {
    async fetchUser({ commit }, { id }) {
      const { data } = await GET(`/users/${id}`)
      commit('setUser', data.user)
      return data
    },
  },

  getters: {
    user: state => state.user,
  },
}
