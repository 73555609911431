<template>
  <section class="test-page">
    <TheLoader v-if="isLoading" />
    <QuizCard
      v-else
      :class="{ 'full-screen': maxmq('sm') }"
      :questions="questions"
      :usedTime="usedTime"
      :maxAnswer="maxAnswer"
      :update-result="updateResult"
      @done="$emit('done')"
    />
  </section>
</template>

<script>
import QuizCard from './components/QuizCard'

export default {
  name: 'TestPage',

  components: {
    QuizCard,
  },

  data() {
    return {
      isLoading: true,
      payload: { id: this.$route.params.id },
      questions: [],
      usedTime: 0,
      maxAnswer: 0,
    }
  },

  mounted() {
    this.loadTest()
  },

  methods: {
    async loadTest() {
      this.isLoading = true

      try {
        const { questions, usedTime, maxAnswer } = await this.$store
          .dispatch('test/getQuestions', this.payload)

        this.questions = questions
        this.usedTime = usedTime
        this.maxAnswer = maxAnswer

        this.status = 'quiz'
      } catch (e) {
        console.error(e)
        return this.$router.push({ name: 'error', props: { e } })
      } finally {
        this.isLoading = false
      }
    },

    async updateResult(rawResult) {
      try {
        return await this.$store.dispatch(
          'test/updateResult',
          { id: this.$route.params.id, rawResult },
        )
      } catch (e) {
        console.error(e)

        this.$notify.error({
          title: this.$t('messages.serverError'),
          message: this.$t('messages.testServerError'),
        })

        return false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/theme/common/var.scss';

.test-page {
  height: 100%;

  .quiz-card {
    margin: 50px auto;
    width: $--md;
    max-width: 100%;
    height: fit-content;

    &.full-screen {
      margin: -20px -20px 20px -20px;
      width: 100vw;
      max-width: unset;
      height: 100%;
      box-shadow: none;
      border: none;

      ::v-deep {
        .question-box {
          margin-left: -10px;
          margin-right: -10px;
          padding-left: 0;
          padding-right: 0;
        }

        .answer-box {
          padding: 0;
          margin-left: -10px;
          margin-right: -10px;
        }
      }
    }
  }
}
</style>
