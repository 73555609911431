import { GET, POST } from '@/plugins/http'
import i18n from '@/plugins/i18n'

export default {
  state: () => ({
    testLocale: i18n.locale,
  }),

  mutations: {
    setTestLocale: (state, locale) => { state.testLocale = locale },
  },

  actions: {
    async getInstruction({ getters }, { id }) {
      const { data } = await GET(`/tests/instruction/${id}`, {
        headers: { 'X-test-locale': getters.testLocale },
      })
      return data.instruction
    },

    async getQuestions({ getters }, { id }) {
      const { data } = await GET(`/tests/test/${id}`, {
        headers: { 'X-test-locale': getters.testLocale },
      })
      return data
    },

    async updateResult(_, { id, rawResult }) {
      const { data } = await POST(`/tests/store/${id}`, rawResult)
      return data
    },

    setTestLocale({ commit }, locale) {
      commit('setTestLocale', locale)
      return locale
    },
  },

  getters: {
    testLocale: state => state.testLocale,
  },
}
