import Vue from 'vue'
import Vuex from 'vuex'
import i18n from '@/plugins/i18n'
import auth from './auth'
import test from './test'

Vue.use(Vuex)

// SET MODULES WITH NAMESPACE HERE:
auth.namespaced = true
test.namespaced = true

export default new Vuex.Store({
  state: () => ({
    locale: i18n.locale,
    isAppLoaded: true,
    router: null,
  }),
  mutations: {
    setLocale: (state, payload) => { state.locale = payload },
    setIsAppLoaded: (state, payload) => { state.isAppLoaded = payload },
    setRouter: (state, router) => { state.router = router },
  },
  actions: {
    setLocale({ commit }, { locale }) {
      i18n.locale = locale
      commit('setLocale', locale)
    },
    setIsAppLoaded({ commit }, isAppLoaded) {
      commit('setIsAppLoaded', isAppLoaded)
      return isAppLoaded
    },
    setRouter({ commit }, router) {
      commit('setRouter', router)
    },
  },
  getters: {
    locale: state => state.locale,
    isAppLoaded: state => state.isAppLoaded,
    router: state => state.router,
  },
  modules: {
    auth,
    test,
  },
})
