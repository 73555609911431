<template>
  <div class="help-icon">
    <i
      v-if="!isOpen"
      class="el-icon-help"
      @click="isOpen = true"
    />

    <div v-else class="info shadow" @mouseleave="isOpen = false">
      <div class="info__item" v-for="item in items" :key="item">
        <div class="info__label">{{ $t(`help.${item}`) }}</div>
        <div class="info__description">{{ $t(`help.${item}Desc`) }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelpIcon',

  data: () => ({
    isOpen: false,
    items: ['arrow', 'enter', 'space'],
  }),
}
</script>

<style scoped lang="scss">
@import '@/styles/theme/common/var.scss';

.help-icon {
  position: absolute;
  right: 20px;
  bottom: 20px;
  max-width: 300px;

  i {
    font-size: 2rem;
    color: $grey-400;
    border-radius: 50%;
    cursor: pointer;
    padding: 4px 2px 2px;

    &:hover {
      box-shadow: 0 0 10px 3px rgba(34, 60, 80, 0.25);
    }
  }

  .info {
    padding: 20px;

    * {
      line-height: 1.2rem;
    }

    &__item {
      margin-bottom: 10px;
    }

    &__label {
      font-weight: bold;
      background-color: $grey-300;
      width: fit-content;
      padding: 4px;
      margin-bottom: 4px;
    }
  }
}
</style>
