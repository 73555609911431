export default {
  message: 'Home page',

  langCode: 'EN',
  langName: 'English',

  core: {
    loading: 'Loading',
    greeting: 'Welcome to',
    description: 'Digital Office Testing System',
    about: 'About D.O.T.S.',
    service: 'Service',
    copyright: 'Copyright 2021 D.O.T.S. LLC.',
    rightsReserved: 'All rights reserved'
  },

  buttons: {
    save: 'Save',
    cancel: 'Cancel',
    login: 'Login',
    signup: 'Signup',
    next: 'Next',
    beginTest: 'Begin test',
    continue: 'Continue'
  },

  messages: {
    leavingWarning: 'If you leave the page now, you cannot take the test again.',
    serverError: 'Server Error',
    testServerError: 'The server is temporarily unavailable. Try the test later.'
  },

  auth: {
    gender: 'Gender',
    firstName: 'First name',
    lastName: 'Last name',
    birthday: 'Year of birth',
    position: 'Position',
    lang: 'Convenient testing language',
    agreeWithUserDataProcessing: 'I do not object to the processing of my personal data'
  },

  gender: {
    male: 'Male',
    female: 'Female'
  },

  tests: {
    isEnoughTime: 'I have enough time to take the test',
    testIsEnd: 'Testing complete.',
    thanksForYourTime: 'Thank you for your time!',
    resultsToHr: 'For the results, please contact your HR specialist',
    ops: 'Oops.',
    wrongLink: 'Your link wrong or expired',
    enoughTime: 'I have enough time to complete the task without being distracted',
    personalData: 'I do not object to the processing of my personal data'
  },

  help: {
    arrow: 'Arrows ↑ ↓',
    arrowDesc: 'Move between answer options.',
    enter: 'Enter ↵',
    enterDesc: 'Confirm the answer and move on to the next question.',
    space: 'Space',
    spaceDesc: 'Interaction with checkbox'
  },

  form: {
    name: {
      login: 'Login',
      signup: 'Signup',
      edit: 'Edit profile',
      create: 'Create profile',
    },
    email: {
      label: 'Email',
      placeholder: 'Please input your email',
    },
    password: {
      label: 'Password',
      placeholder: 'Please input your password',
    },
    confirmPassword: {
      label: 'Confirm password',
      placeholder: 'Please confirm your password',
    },
    firstName: {
      label: 'First name',
      placeholder: 'Please input your first name',
    },
    lastName: {
      label: 'Last name',
      placeholder: 'Please input your last name',
    },
    birthday: {
      label: 'Birthday',
    },
    gender: {
      label: 'Gender',
      male: 'Male',
      female: 'Female',
    },
    subdivision: {
      label: 'Subdivision',
    },
    position: {
      label: 'Position',
    },
    status: {
      label: 'Status',
    },
    targetPosition: {
      label: 'Target position',
    },
    notes: {
      label: 'Notes',
    },
  },

  el: {
    datepicker: {
      placeholder: 'Please select your birthday',
      now: 'Now',
      today: 'Today',
      cancel: 'Cancel',
      clear: 'Clear',
      confirm: 'OK',
      selectDate: 'Select date',
      selectTime: 'Select time',
      startDate: 'Start date',
      startTime: 'Start time',
      endDate: 'End date',
      endTime: 'End time',
      prevYear: 'Previous year',
      nextYear: 'Next year',
      prevMonth: 'Previous month',
      nextMonth: 'Next month',
      year: '',
      month1: 'January',
      month2: 'February',
      month3: 'March',
      month4: 'April',
      month5: 'May',
      month6: 'June',
      month7: 'July',
      month8: 'August',
      month9: 'September',
      month10: 'October',
      month11: 'November',
      month12: 'December',
      weeks: {
        sun: 'Sun',
        mon: 'Mon',
        tue: 'Tue',
        wed: 'Wed',
        thu: 'Thu',
        fri: 'Fri',
        sat: 'Sat',
      },
      months: {
        jan: 'Jan',
        feb: 'Feb',
        mar: 'Mar',
        apr: 'Apr',
        may: 'May',
        jun: 'Jun',
        jul: 'Jul',
        aug: 'Aug',
        sep: 'Sep',
        oct: 'Oct',
        nov: 'Nov',
        dec: 'Dec',
      },
    },
    select: {
      noData: 'No data',
      placeholder: 'Select',
    },
  },
}
