const requireModule = require.context('./translations', false, /.*\.js$/)

const Translations = {
  messages: {},
  descriptions: {},
  locales: [],
}

const langCollection = requireModule.keys().reduce((translations, path) => {
  const { default: lang } = requireModule(path)
  const localeCode = path.match(/\.\/([\w-]+)\.js$/i)[1]
  const [langCode, countryCode, suffix] = localeCode.split('-')

  if (suffix === 'descriptions') {
    Object.assign(translations.descriptions, { [langCode]: lang })
  } else {
    translations.locales.push({
      langCode,
      countryCode,
      name: lang.langName,
      localeCode,
    })

    Object.assign(translations.messages, { [langCode]: lang })
  }

  return translations
}, Translations)

export default langCollection
