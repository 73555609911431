import axios from 'axios'
import { env, errNotify, getTimeZone } from '@/services'
import i18n from '@/plugins/i18n'

axios.defaults.baseURL = env.baseURL
axios.defaults.withCredentials = true
axios.defaults.headers.common['X-timezone'] = getTimeZone()
axios.defaults.headers.common['Accept-Language'] = i18n.locale

axios.interceptors.response.use(
  response => response,
  error => {
    // TODO: update error handling and messages
    errNotify(error)
    return Promise.reject(error)
  },
)

export const GET = (url, options) => axios.get(url, options)
export const POST = (url, data, options) => axios.post(url, data, options)
export const PUT = (url, data, options) => axios.put(url, data, options)
export const DELETE = (url, options) => axios.delete(url, options)
