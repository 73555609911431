<template>
  <section class="error-page">
    <el-card class="box-card error-card">
      <h2>{{ $t('tests.ops') }}</h2>
      <h3>{{ $t('tests.wrongLink') }}</h3>
    </el-card>
  </section>
</template>

<script>
export default {
  name: 'ErrorPage',
}
</script>

<style scoped lang="scss">
@import '@/styles/theme/common/var.scss';

.error-page {
  .box-card {
    margin: 50px auto;
    text-align: center;
    max-width: 600px;

    * {
      color: $red-400;
    }
  }
}
</style>
