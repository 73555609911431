<template>
  <section class="user-page">
    <div class="logo-container mb-4">
      <div class="logo-container__title mb-2">
        <h1>{{ $t('core.greeting') }}</h1>
      </div>

      <TheLogo class="mb-4" />

      <div class="logo-container__description">
        <span>{{ $t('core.description') }}</span>
      </div>
    </div>

    <TheLoader v-if="isLoading" />

    <template v-else>
      <div class="user-data">
        <UserForm
          readonly
          :label-position="maxmq('sm') ? 'top' : 'right'"
          :test-data="testData"
          :value="userData"
          v-bind.sync="userData"
        />
      </div>

      <el-row justify="center" class="mb-4">
        <el-checkbox v-model="isAgree">
          {{ $t('auth.agreeWithUserDataProcessing') }}
        </el-checkbox>
      </el-row>

      <el-row justify="center" class="btns">
        <el-button
          type="warning"
          :disabled="!isAgree"
          @click="$router.push({
            name: 'test',
            params: { id: $route.params.id }
          })"
        >
          {{ $t('buttons.continue') }}
        </el-button>
      </el-row>
    </template>
  </section>
</template>

<script>
import { TheLogo } from '@/modules/core'
import UserForm from './components/UserForm'

export default {
  name: 'UserPage',

  components: {
    TheLogo,
    UserForm,
  },

  data() {
    return {
      isLoading: true,
      userData: null,
      testData: null,
      isAgree: false,
    }
  },

  created() {
    this.loadUser()
  },

  methods: {
    async loadUser() {
      try {
        const pl = { id: this.$route.params.id }
        const { user, testData } = await this.$store
          .dispatch('auth/fetchUser', pl)

        this.userData = user
        this.testData = testData
      } catch (e) {
        console.error(e)
        // TODO use it global
        return this.$router.push({ name: 'error', props: { e } })
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/styles/theme/common/var.scss';

.user-page {
  .logo-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__description {
      padding: 10px 20px;
      letter-spacing: .4rem;
      border-bottom: 1px solid $grey-300;
    }
  }

  .user-data {
    max-width: 400px;
    margin: 0 auto 40px;
  }

  .btns {
    margin: 0 auto;
    padding: 0 100px;

    .el-button {
      width: 150px;
    }
  }

  @media all and (max-width: $--sm) {
    .logo-container__title * {
      font-size: 1.4rem;
    }

    .the-logo {
      margin-bottom: 0;
    }

    .logo-container__description {
      padding: 4px 0;
      margin: 10px -8px;
      text-align: center;
      font-size: .4rem;
    }
  }
}
</style>
