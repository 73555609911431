<template>
  <div @click.stop class="locale-switcher">
    <el-select
      class="short"
      :value="$i18n.locale"
      @change="setLocale"
    >
      <template #prefix>
        <span class="title color-default">{{ $t('langCode') }}</span>
      </template>
      <el-option
        v-for="locale in locales"
        :label="locale.label"
        :value="locale.value"
        :key="locale.value"
      />
    </el-select>
  </div>
</template>

<script>
export default {
  name: 'LocaleSwitcher',

  data() {
    return {
      locales: this.$i18n.availableLocales
        .map(value => ({ value, label: this.$i18n.messages[value].langName })),
    }
  },

  methods: {
    setLocale(locale) {
      this.$store.dispatch('setLocale', { locale })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/theme/common/var.scss';

.locale-switcher {
  display: flex;
  align-items: center;
  padding-top: 8px;

  .el-select {
    display: flex;
    height: 24px;
    cursor: pointer;
    width: 30px;

    /deep/ * {
      height: 20px!important;
    }

    /deep/ .el-input__prefix {
      margin: auto;
      font-size: 0.8rem;
    }

    .title {
      text-transform: uppercase;
    }
  }

  /deep/ .el-submenu__icon-arrow {
    display: none;
  }

  /deep/ .el-input__suffix-inner {
    display: block;
  }

  /deep/ .el-input__inner {
    outline: none;
    border: none;
    display: none;
  }

  /deep/ .el-select__caret:before {
    display: none;
  }

  /deep/ .el-input__prefix {
    display: flex;
    top: 1px;
  }

  .title {
    font-size: 1rem;
  }
}
</style>
