<template>
  <img
    class="the-logo"
    src="@/assets/img/logo.svg"
    alt="Digital Office Test System"
  />
</template>

<script>
export default {
  name: 'TheLogo',
}
</script>

<style lang="scss" scoped>
img {
  display: block;
}
</style>
