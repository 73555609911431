<template>
  <el-card class="quiz-card">
    <div class="header-box mb-2">
      <el-row justify="space-between" align="middle" class="mb-1">
        <div class="progress">
          {{ currentQuestionIndex }} / {{ maxAnswer }}
        </div>

        <TestTimer :usedTime="usedTime" />
      </el-row>

      <el-progress color="#e6a23c" :percentage="percentage"></el-progress>
    </div>

    <div class="question-box mb-2">
      {{ `${currentQuestionIndex}. ${currentQuestion.question}` }}
    </div>

    <div :class="['answer-box mb-4', { highlight: isHighlight }]" :key="key">
      <el-radio-group
        ref="radio"
        v-if="currentQuestion.answerType === 'radio'"
        v-model="radioValue"
      >
        <el-radio
          v-for="answer in currentQuestion.answers"
          :key="answer.key"
          :label="answer.key"
          :ref="answer.key"
        >
          {{ answer.key.toUpperCase() }}. {{ answer.value }}
        </el-radio>
      </el-radio-group>

      <el-checkbox-group
        ref="checkbox"
        v-else-if="currentQuestion.answerType === 'checkbox'"
        v-model="checkboxValue"
      >
        <el-checkbox
          v-for="answer in currentQuestion.answers"
          :key="answer.key"
          :label="answer.key"
          :ref="answer.key"
        >
          {{ answer.key.toUpperCase() }}. {{ answer.value }}
        </el-checkbox>
      </el-checkbox-group>

      <el-input
        autofocus
        ref="input"
        v-else-if="currentQuestion.answerType === 'input'"
        v-model="inputValue"
      />
    </div>

    <el-row justify="center" class="mt-4">
      <el-button
        type="warning"
        :disabled="!isAnswered || isLoading"
        @click="next"
      >
        {{ $t('buttons.next') }}
      </el-button>
    </el-row>
  </el-card>
</template>

<script>
import { last, head, isEmpty } from '@/services'
import TestTimer from './TestTimer'

export default {
  name: 'QuizCard',

  components: {
    TestTimer,
  },

  props: {
    questions: { type: Array, required: true },
    usedTime: { type: Number, required: true },
    maxAnswer: { type: Number, required: true },
    updateResult: { type: Function, required: true },
  },

  data() {
    return {
      isLoading: false,
      isHighlight: false,
      currentIndex: 0,
      currentQuestionIndex: 1,
      radioValue: null,
      checkboxValue: [],
      inputValue: '',
      rawResults: {},
      key: 0,
    }
  },

  computed: {
    currentQuestion() {
      return this.questions[this.currentIndex]
    },

    lastQuestionNumber() {
      return this.questions.length
    },

    percentage() {
      return Number(
        (((this.currentQuestionIndex - 1) * 100)
         / this.maxAnswer).toFixed(1),
      )
    },

    isAnswered() {
      return !isEmpty(this[`${this.currentQuestion.answerType}Value`])
    },
  },

  mounted() {
    this.startKeysWatch()
    this.currentQuestionIndex = (this.maxAnswer - this.questions.length) + 1
  },

  beforeDestroy() {
    document.removeEventListener('keydown', this.listener)
  },

  methods: {
    async next() {
      this.isLoading = true
      const isOk = await this.updateResults()

      if (!isOk) {
        return null
      }

      if (this.currentIndex + 1 < this.lastQuestionNumber) {
        this.nextQuestion()
      } else {
        this.done()
      }

      this.$nextTick(() => { this.isLoading = false })
    },

    nextQuestion() {
      this.currentIndex += 1
      this.currentQuestionIndex += 1

      if (this.currentQuestion.answerType === 'input') {
        this.$nextTick(() => {
          if (this.$refs.input) {
            this.$refs.input.focus()
          }
        })
      }
    },

    clearCurrentAnswer() {
      this.radioValue = null
      this.checkboxValue = []
      this.inputValue = ''
      this.key += 1
    },

    async updateResults() {
      const valueName = `${this.currentQuestion.answerType}Value`
      const key = this.currentQuestion.id
      this.$set(this.rawResults, key, this[valueName])

      const isOk = await this.updateResult({ key, value: this[valueName] })

      if (isOk) {
        this.clearCurrentAnswer()
        return true
      }
    },

    done() {
      this.$emit('done', this.rawResults)
    },

    startKeysWatch() {
      this.listener = event => {
        if (this.isLoading) {
          return null
        }

        // TODO this line block all keyboard events...
        if (this.currentQuestion.answerType === 'input') {
          if (event.keyCode === 13 && this.isAnswered) {
            this.next()
          }

          return null
        }

        if (event.keyCode === 13 && this.isAnswered) { // enter
          event.preventDefault()
          this.next()
        } if (event.keyCode === 40) { // down arrow
          event.preventDefault()
          this.setAnswerIndex(1, event)
        } else if (event.keyCode === 38) { // up arrow
          event.preventDefault()
          this.setAnswerIndex(-1, event)
        } else if (
          event.keyCode === 32 // spacebar
          && this.currentQuestion.answerType === 'checkbox'
        ) {
          event.preventDefault()
          this.toggleCheckbox(event)
        } else if (
          event.keyCode === 13 && !this.isAnswered && !this.isLoading
        ) {
          event.preventDefault()
          this.isHighlight = true
          this.$nextTick()
          setTimeout(() => { this.isHighlight = false }, 500)
        }
      }

      document.addEventListener('keydown', this.listener)
    },

    setAnswerIndex(direction, event) {
      if (this.currentQuestion.answerType === 'radio') {
        this.focusOnRadio(direction, event)
      } else if (this.currentQuestion.answerType === 'checkbox') {
        this.focusOnCheckbox(direction, event)
      }
    },

    focusOnRadio(direction, event) {
      const { answers } = this.currentQuestion
      const isChecked = this.$refs.radio.$children
        .some(item => item.$el.classList.contains('is-checked'))
      const isFocus = event.currentTarget.activeElement.classList
        .contains('el-radio')

      if (!isChecked) {
        // if no selected - set first
        this.radioValue = answers[0].key
        this.$refs.radio.$children[0].$el.focus()
      } else if (!isFocus) {
        // if radio within focus -
        // check selected and dispatch current event again
        const el = this.$refs.radio.$children
          .find(item => item.$el.classList.contains('is-checked'))

        if (el) {
          el.$el.focus()
          let ev = null

          if (window.KeyEvent) {
            ev = document.createEvent('KeyEvents')
            ev.initKeyEvent(
              'keydown', true, true, window, false, false, false, false,
              event.keyCode, event.charCode,
            )
          } else {
            ev = document.createEvent('UIEvents')
            ev.initUIEvent('keydown', true, true, window, 1)
            ev.keyCode = event.keyCode
          }

          el.$el.dispatchEvent(ev)
        }
      }
    },

    focusOnCheckbox(direction, event) {
      const isFocus = event.currentTarget.activeElement.className
        .includes('el-checkbox')

      if (isFocus) {
        const index = this.$refs.checkbox.$children
          .findIndex(item => item.focus)
        const next = direction > 0 ? index + 1 : index - 1

        if (index > -1 && this.$refs.checkbox.$children[next]) {
          this.$refs.checkbox.$children[next].$el.focus()
        } else if (index > -1 && direction < 0) {
          // if active is first and go to top - set last
          last(this.$refs.checkbox.$children).$el.focus()
        } else if (index > -1 && direction > 0) {
          // if active is last and go to bottom - set first
          head(this.$refs.checkbox.$children).$el.focus()
        }
      } else {
        // if checkbox within focus - set first
        head(this.$refs.checkbox.$children).$el.focus()
      }
    },

    toggleCheckbox(event) {
      const elem = event.currentTarget.activeElement
      elem.click()
    },
  },
}
</script>

<style scoped lang="scss" >
@import '@/styles/theme/common/var.scss';

.header-box {
  .progress {
    float: left;
    font-size: 24px;
    font-weight: bold;
  }

  .progress, .test-timer {
    color:$grey-600;
  }
}

.question-box {
  min-height: 110px;
  padding: 20px;
  border-radius: 4px;
  color: $grey-A400;
  font-size: 20px;
  pointer-events: none;
  user-select: none;
}

.answer-box {
  border: 1px solid transparent;
  border-radius: 4px;
  margin: -6px;
  padding: 6px;
  min-height: 100px;

  .el-checkbox {
    display: flex;
    align-items: center;
    width: fit-content;
  }

  /deep/.el-checkbox__label, /deep/.el-radio__label {
    font-size: 20px;
    color: $black;
  }

  /deep/ .el-checkbox.is-checked .el-checkbox__label,
  /deep/ .el-radio__input.is-checked + .el-radio__label {
    font-weight: bold;
    color: $black;
  }

  &.highlight {
    border: 1px solid $red-200;
  }
}

@media all and (max-width: $--sm) {
  .question-box {
    font-size: 1rem;
  }

  .answer-box ::v-deep * {
    font-size: 1rem!important;
  }
}
</style>
