<template>
  <section class="test-page">
    <InstructionPage
      v-if="status === 'instruction'"
      @next="status = 'test'"
    />

    <TestPage
      v-else-if="status === 'test'"
      @done="status = 'done'"
    />

    <DonePage v-else-if="status === 'done'" />

    <HelpIcon />
  </section>
</template>

<script>
import InstructionPage from './InstructionPage'
import TestPage from './TestPage'
import DonePage from './DonePage'
import HelpIcon from './components/HelpIcon'

export default {
  name: 'TestWrapper',

  components: {
    InstructionPage,
    TestPage,
    DonePage,
    HelpIcon,
  },

  data: () => ({
    status: 'instruction',
  }),
}
</script>
