import Vue from 'vue'
import VueMq from 'vue-mq'

// sm - mobile
export const breakpointList = [
  { name: 'xs', value: 600 },
  { name: 'sm', value: 768 },
  { name: 'md', value: 865 },
  { name: 'lg', value: 1200 },
  { name: 'xl', value: 1600 },
  { name: 'if', value: Infinity },
]

export const breakpoints = breakpointList
  .reduce((acc, { name, value }) => ({ ...acc, [name]: value }), {})

Vue.use(VueMq, {
  breakpoints,
  defaultBreakpoint: 'hg',
})
