<template>
  <section class="instruction-page">
    <div class="logo-container mb-4">
      <div class="logo-container__title mb-2">
        <h1>{{ $t('core.greeting') }}</h1>
      </div>

      <TheLogo class="mb-4" />

      <div class="logo-container__description">
        <h4>{{ $t('core.description') }}</h4>
      </div>
    </div>

    <TheLoader v-if="isLoading" />

    <div class="content pg-0-4" >
      <p v-html="instruction" />
    </div>

    <el-row justify="center" class="mt-4 mb-4">
      <el-checkbox v-model="isEnoughTime">
        {{ $t('tests.isEnoughTime') }}
      </el-checkbox>
    </el-row>

    <el-row justify="center" align="middle" class="btns">
      <el-button
        type="warning"
        :disabled="!isEnoughTime"
        @click="$emit('next')"
      >
        {{ $t('buttons.continue') }}
      </el-button>
    </el-row>
  </section>
</template>

<script>
import { TheLogo } from '@/modules/core'

export default {
  name: 'InstructionPage',

  components: {
    TheLogo,
  },

  data() {
    return {
      isLoading: true,
      isEnoughTime: false,
      instruction: '',
    }
  },

  created() {
    this.loadInstruction()
  },

  methods: {
    async loadInstruction() {
      try {
        const payload = { id: this.$route.params.id }
        this.instruction = await this.$store
          .dispatch('test/getInstruction', payload)
      } catch (e) {
        console.error(e)
        // TODO use it global
        return this.$router.push({ name: 'error', props: { e } })
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/styles/theme/common/var.scss';

.instruction-page {
  .logo-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__description {
      padding: 10px 20px;
      letter-spacing: .4rem;
      border-bottom: 1px solid $grey-300;
    }
  }

  .content {
    margin: 0 auto;
    max-width: 800px;
  }

  .btns {
    margin: 40px auto;
    padding: 0 100px;

    .el-button {
      width: 150px;
    }
  }

  @media screen and (max-width: $--sm) {
    .logo-container {
      text-align: center;

      &__title h1 {
        font-size: 1.4rem;
      }

      &__description {
        padding: 0;
      }
    }

    .content {
      padding: 0;
    }
  }
}
</style>
