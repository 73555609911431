<template>
  <div class="the-loader__container">
    <div class="the-loader">
      <div class="loader" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'TheLoader',
}
</script>

<style scoped lang="scss">
.the-loader__container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.the-loader {
  margin: 50px auto;
  border: 1px solid rgba(255, 255, 255, 0.2);
  width: 240px;
  height: 240px;
  float: left;
  position: relative;
  overflow: hidden;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  .loader {
    margin: 100px auto;
    font-size: 25px;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    position: relative;
    text-indent: -9999em;
    -webkit-animation: load5 1.1s infinite ease;
    animation: load5 1.1s infinite ease;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }
  @-webkit-keyframes load5 {
    0%,
    100% {
      box-shadow: 0em -2.6em 0em 0em #ffc524,
      1.8em -1.8em 0 0em rgba(255,197,36, 0.2),
      2.5em 0em 0 0em rgba(255,197,36, 0.2),
      1.75em 1.75em 0 0em rgba(255,197,36, 0.2),
      0em 2.5em 0 0em rgba(255,197,36, 0.2),
      -1.8em 1.8em 0 0em rgba(255,197,36, 0.2),
      -2.6em 0em 0 0em rgba(255,197,36, 0.5),
      -1.8em -1.8em 0 0em rgba(255,197,36, 0.7);
    }
    12.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(255,197,36, 0.7),
      1.8em -1.8em 0 0em #ffc524,
      2.5em 0em 0 0em rgba(255,197,36, 0.2),
      1.75em 1.75em 0 0em rgba(255,197,36, 0.2),
      0em 2.5em 0 0em rgba(255,197,36, 0.2),
      -1.8em 1.8em 0 0em rgba(255,197,36, 0.2),
      -2.6em 0em 0 0em rgba(255,197,36, 0.2),
      -1.8em -1.8em 0 0em rgba(255,197,36, 0.5);
    }
    25% {
      box-shadow: 0em -2.6em 0em 0em rgba(255,197,36, 0.5),
      1.8em -1.8em 0 0em rgba(255,197,36, 0.7),
      2.5em 0em 0 0em #ffc524,
      1.75em 1.75em 0 0em rgba(255,197,36, 0.2),
      0em 2.5em 0 0em rgba(255,197,36, 0.2),
      -1.8em 1.8em 0 0em rgba(255,197,36, 0.2),
      -2.6em 0em 0 0em rgba(255,197,36, 0.2),
      -1.8em -1.8em 0 0em rgba(255,197,36, 0.2);
    }
    37.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(255,197,36, 0.2),
      1.8em -1.8em 0 0em rgba(255,197,36, 0.5),
      2.5em 0em 0 0em rgba(255,197,36, 0.7),
      1.75em 1.75em 0 0em #ffc524,
      0em 2.5em 0 0em rgba(255,197,36, 0.2),
      -1.8em 1.8em 0 0em rgba(255,197,36, 0.2),
      -2.6em 0em 0 0em rgba(255,197,36, 0.2),
      -1.8em -1.8em 0 0em rgba(255,197,36, 0.2);
    }
    50% {
      box-shadow: 0em -2.6em 0em 0em rgba(255,197,36, 0.2),
      1.8em -1.8em 0 0em rgba(255,197,36, 0.2),
      2.5em 0em 0 0em rgba(255,197,36, 0.5),
      1.75em 1.75em 0 0em rgba(255,197,36, 0.7),
      0em 2.5em 0 0em #ffc524,
      -1.8em 1.8em 0 0em rgba(255,197,36, 0.2),
      -2.6em 0em 0 0em rgba(255,197,36, 0.2),
      -1.8em -1.8em 0 0em rgba(255,197,36, 0.2);
    }
    62.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(255,197,36, 0.2),
      1.8em -1.8em 0 0em rgba(255,197,36, 0.2),
      2.5em 0em 0 0em rgba(255,197,36, 0.2),
      1.75em 1.75em 0 0em rgba(255,197,36, 0.5),
      0em 2.5em 0 0em rgba(255,197,36, 0.7),
      -1.8em 1.8em 0 0em #ffc524,
      -2.6em 0em 0 0em rgba(255,197,36, 0.2),
      -1.8em -1.8em 0 0em rgba(255,197,36, 0.2);
    }
    75% {
      box-shadow: 0em -2.6em 0em 0em rgba(255,197,36, 0.2),
      1.8em -1.8em 0 0em rgba(255,197,36, 0.2),
      2.5em 0em 0 0em rgba(255,197,36, 0.2),
      1.75em 1.75em 0 0em rgba(255,197,36, 0.2),
      0em 2.5em 0 0em rgba(255,197,36, 0.5),
      -1.8em 1.8em 0 0em rgba(255,197,36, 0.7),
      -2.6em 0em 0 0em #ffc524,
      -1.8em -1.8em 0 0em rgba(255,197,36, 0.2);
    }
    87.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(255,197,36, 0.2),
      1.8em -1.8em 0 0em rgba(255,197,36, 0.2),
      2.5em 0em 0 0em rgba(255,197,36, 0.2),
      1.75em 1.75em 0 0em rgba(255,197,36, 0.2),
      0em 2.5em 0 0em rgba(255,197,36, 0.2),
      -1.8em 1.8em 0 0em rgba(255,197,36, 0.5),
      -2.6em 0em 0 0em rgba(255,197,36, 0.7),
      -1.8em -1.8em 0 0em #ffc524;
    }
  }
  @keyframes load5 {
    0%,
    100% {
      box-shadow: 0em -2.6em 0em 0em #ffc524,
      1.8em -1.8em 0 0em rgba(255,197,36, 0.2),
      2.5em 0em 0 0em rgba(255,197,36, 0.2),
      1.75em 1.75em 0 0em rgba(255,197,36, 0.2),
      0em 2.5em 0 0em rgba(255,197,36, 0.2),
      -1.8em 1.8em 0 0em rgba(255,197,36, 0.2),
      -2.6em 0em 0 0em rgba(255,197,36, 0.5),
      -1.8em -1.8em 0 0em rgba(255,197,36, 0.7);
    }
    12.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(255,197,36, 0.7),
      1.8em -1.8em 0 0em #ffc524,
      2.5em 0em 0 0em rgba(255,197,36, 0.2),
      1.75em 1.75em 0 0em rgba(255,197,36, 0.2),
      0em 2.5em 0 0em rgba(255,197,36, 0.2),
      -1.8em 1.8em 0 0em rgba(255,197,36, 0.2),
      -2.6em 0em 0 0em rgba(255,197,36, 0.2),
      -1.8em -1.8em 0 0em rgba(255,197,36, 0.5);
    }
    25% {
      box-shadow: 0em -2.6em 0em 0em rgba(255,197,36, 0.5),
      1.8em -1.8em 0 0em rgba(255,197,36, 0.7),
      2.5em 0em 0 0em #ffc524,
      1.75em 1.75em 0 0em rgba(255,197,36, 0.2),
      0em 2.5em 0 0em rgba(255,197,36, 0.2),
      -1.8em 1.8em 0 0em rgba(255,197,36, 0.2),
      -2.6em 0em 0 0em rgba(255,197,36, 0.2),
      -1.8em -1.8em 0 0em rgba(255,197,36, 0.2);
    }
    37.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(255,197,36, 0.2),
      1.8em -1.8em 0 0em rgba(255,197,36, 0.5),
      2.5em 0em 0 0em rgba(255,197,36, 0.7),
      1.75em 1.75em 0 0em #ffc524,
      0em 2.5em 0 0em rgba(255,197,36, 0.2),
      -1.8em 1.8em 0 0em rgba(255,197,36, 0.2),
      -2.6em 0em 0 0em rgba(255,197,36, 0.2),
      -1.8em -1.8em 0 0em rgba(255,197,36, 0.2);
    }
    50% {
      box-shadow: 0em -2.6em 0em 0em rgba(255,197,36, 0.2),
      1.8em -1.8em 0 0em rgba(255,197,36, 0.2),
      2.5em 0em 0 0em rgba(255,197,36, 0.5),
      1.75em 1.75em 0 0em rgba(255,197,36, 0.7),
      0em 2.5em 0 0em #ffc524,
      -1.8em 1.8em 0 0em rgba(255,197,36, 0.2),
      -2.6em 0em 0 0em rgba(255,197,36, 0.2),
      -1.8em -1.8em 0 0em rgba(255,197,36, 0.2);
    }
    62.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(255,197,36, 0.2),
      1.8em -1.8em 0 0em rgba(255,197,36, 0.2),
      2.5em 0em 0 0em rgba(255,197,36, 0.2),
      1.75em 1.75em 0 0em rgba(255,197,36, 0.5),
      0em 2.5em 0 0em rgba(255,197,36, 0.7),
      -1.8em 1.8em 0 0em #ffc524,
      -2.6em 0em 0 0em rgba(255,197,36, 0.2),
      -1.8em -1.8em 0 0em rgba(255,197,36, 0.2);
    }
    75% {
      box-shadow: 0em -2.6em 0em 0em rgba(255,197,36, 0.2),
      1.8em -1.8em 0 0em rgba(255,197,36, 0.2),
      2.5em 0em 0 0em rgba(255,197,36, 0.2),
      1.75em 1.75em 0 0em rgba(255,197,36, 0.2),
      0em 2.5em 0 0em rgba(255,197,36, 0.5),
      -1.8em 1.8em 0 0em rgba(255,197,36, 0.7),
      -2.6em 0em 0 0em #ffc524,
      -1.8em -1.8em 0 0em rgba(255,197,36, 0.2);
    }
    87.5% {
      box-shadow: 0em -2.6em 0em 0em rgba(255,197,36, 0.2),
      1.8em -1.8em 0 0em rgba(255,197,36, 0.2),
      2.5em 0em 0 0em rgba(255,197,36, 0.2),
      1.75em 1.75em 0 0em rgba(255,197,36, 0.2),
      0em 2.5em 0 0em rgba(255,197,36, 0.2),
      -1.8em 1.8em 0 0em rgba(255,197,36, 0.5),
      -2.6em 0em 0 0em rgba(255,197,36, 0.7),
      -1.8em -1.8em 0 0em #ffc524;
    }
  }
}
</style>
