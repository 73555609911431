import { cloneDeep } from './functions'

const user = {
  id: null,
  firstName: '',
  lastName: '',
  birthday: null,
  gender: '',
  positionId: null,
  position: {
    id: null,
    name: 'null',
    description: '',
  },
}

export const defaultEntities = {
  user,
}

export const getDefault = name => {
  const entity = defaultEntities[name]

  return entity
    ? cloneDeep(entity)
    : console.error(`THERE IS NO DEFAULT ENTITY: ${name}`)
}
