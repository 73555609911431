<template>
  <section class="done-page">
    <div class="mb-2">
      <h1>{{ $t('tests.testIsEnd') }}</h1>
    </div>
    <div class="mb-2">
      <h3>{{ $t('tests.thanksForYourTime') }}</h3>
    </div>
    <div>
      <h3>{{ $t('tests.resultsToHr') }}</h3>
    </div>
  </section>
</template>

<script>
export default {
  name: 'DonePage',
}
</script>

<style scoped lang="scss">
.done-page {
  text-align: center;
}
</style>
