<template>
  <div class="test-timer"><span>{{ time }}</span></div>
</template>

<script>
const HOUR = 1000 * 60 * 60
const MINUTE = 1000 * 60
const SECOND = 1000

const getStr = time => (time < 10 ? `0${time}` : time)

export default {
  name: 'TestTimer',

  props: {
    usedTime: { type: Number, required: false },
  },

  data() {
    return {
      time: '00:00:00',
      startTime: Date.now(),
      timer: null,
    }
  },

  created() {
    this.start()
  },

  beforeDestroy() {
    this.stop()
  },

  methods: {
    render() {
      const now = Date.now()
      const difference = now - this.startTime + (this.usedTime || 0)

      const hours = Math.floor((difference % (HOUR * 24)) / HOUR)
      const minutes = Math.floor((difference % HOUR) / MINUTE)
      const seconds = Math.floor((difference % MINUTE) / SECOND)

      this.time = `${getStr(hours)}:${getStr(minutes)}:${getStr(seconds)}`
    },

    start() {
      this.timer = setInterval(this.render, SECOND)
    },

    stop() {
      if (this.timer) {
        clearInterval(this.timer)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.test-timer {
  font-size: 24px;
  font-weight: bold;
}
</style>
