import Vue from 'vue'
import VueRouter from 'vue-router'
import { routes } from './routes'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace

const router = new VueRouter({
  mode: 'history',
  routes,
})

// avoided redundant navigation to current location.
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(e => e)
}

VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(e => e)
}

export default router
