<template>
  <div class="user-form">
    <el-form
      label-width="200px"
      size="mini"
      :label-position="labelPosition"
      :model="formData"
      @submit.prevent
    >
      <el-form-item prop="gender" :label="$t('auth.gender')">
        <el-select :disabled="readonly" :value="formData.gender">
          <el-option
            v-for="gender in ['male', 'female']"
            :label="$t(`gender.${gender}`)"
            :value="gender"
            :key="gender"
          />
        </el-select>
      </el-form-item>
      <el-form-item prop="firstName" :label="$t('auth.firstName')">
        <el-input :disabled="readonly" :value="formData.firstName" />
      </el-form-item>
      <el-form-item prop="lastName" :label="$t('auth.lastName')">
        <el-input :disabled="readonly" :value="formData.lastName" />
      </el-form-item>
      <el-form-item prop="lastName" :label="$t('auth.birthday')">
        <el-input :disabled="readonly" :value="formData.birthday" />
      </el-form-item>
      <el-form-item prop="position.name" :label="$t('auth.position')">
        <el-input :disabled="readonly" :value="formData.position.name" />
      </el-form-item>

      <el-form-item prop="lang" :label="$t('auth.lang')">
        <el-select :value="locale" @change="selLocale">
          <el-option
            v-for="locale in locales"
            :label="locale.label"
            :value="locale.value"
            :key="locale.value"
          />
        </el-select>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {
  cloneDeep,
  get,
  getDefault,
  head,
  testLanguages,
} from '@/services'

const getLangList = (codes, vm) => codes.map(value => ({
  value,
  label: (vm.$i18n.messages[value] || {}).langName
    || testLanguages[value]
    || value.toUpperCase(),
}))

export default {
  name: 'UserForm',

  props: {
    readonly: Boolean,
    labelPosition: { type: String, default: 'right' },
    value: { type: Object, required: true },
    testData: Object,
  },

  data: () => ({
    formData: getDefault('user'),
  }),

  computed: {
    locale() {
      const currentLocale = this.$store.getters['test/testLocale']
      const propLanguages = get(this, 'testData.availableLanguages', [])

      const testLocale = propLanguages.includes(currentLocale)
        ? currentLocale
        : head(propLanguages)

      this.selLocale(testLocale)
      return testLocale
    },

    locales() {
      const propLanguages = get(this, 'testData.availableLanguages', [])

      return propLanguages.length
        ? getLangList(propLanguages, this)
        : getLangList(Object.keys(this.$i18n.messages), this)
    },
  },

  created() {
    if (this.value) {
      this.formData = cloneDeep(this.value)
    }
  },

  methods: {
    selLocale(locale) {
      if (this.$i18n.availableLocales.includes(locale)) {
        this.$store.dispatch('setLocale', { locale })
      }

      this.$store.dispatch('test/setTestLocale', locale)
    },
  },
}
</script>

<style scoped lang="scss">
.user-form {
  .el-form-item {
    margin-bottom: 10px;
  }
}
</style>
